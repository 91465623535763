import React from 'react'
import {Container, Grid, Typography, Button} from '@mui/material'
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import superintendencia from './assets/images/superintendencia.svg'

import './styles.scss'

const comercialCaseSNR : React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{my: 5}} id="casos-exito">
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="stretch"
        wrap="wrap"
      >
        <Grid item xs={12} md={6}>
          <img src={superintendencia} alt="Habi-Kushki" className="habi-k_img" />
        </Grid>
        <Grid item xs={12} md={6} className="habi-k_grid">
          <Typography variant="h5" gutterBottom color="alternate.dark2">
            Caso de éxito
          </Typography>
          <Typography variant="h2" gutterBottom color="text.dark">
            Superintendencia de Notariado y Registro
          </Typography>
          <Typography variant="body2" sx={{ my: 3 }} gutterBottom color="text.dark">
            Conoce nuestro caso de éxito con la <strong>Superintendencia de Notariado y Registro (SNR)</strong> y cómo nuestra unión ha ayudado a miles de colombianos y empresas nacionales a tomar mejores decisiones de compra.
          </Typography>
          <OutboundLink
            href='https://www.kushki.com/blog/ingenio-kushki-fomenta-la-digitalizacion-de-certificados-oficiales-para-los'
            target={'_blank'}
            className="about-kushki_link"
          >
            <Button variant="contained" sx={{ my: 3 }} color="primary">
              Ver caso completo
            </Button>
          </OutboundLink>
        </Grid>
      </Grid>
    </Container>
  )
}

export default comercialCaseSNR;