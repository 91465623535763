import React from 'react'
import { Grid, Typography, Button, Container, Box } from '@mui/material'
import { Link } from 'gatsby'
import comercialesLogo from './assets/images/comercialesLogo.svg';
import comercialesHero from './assets/images/comercialesHero.svg';
import comercialesHeroMobile from './assets/images/comercialesHeroMobile.png';
import './styles.scss';

const ComercialesHero: React.FC = () => {
  return (
    <Container >
      <Grid container p="2rem" >
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', mt:{xs:'4rem', sm:'4rem' ,md:'1rem'} }}>
          <Typography color="text.white" gutterBottom variant="h2">
            Ofrecemos acceso a documentos y trámites para múltiples industrias junto a entidades públicas y privadas
          </Typography>
          <Typography color="text.white" gutterBottom variant="body2" className="hero-title-comerciales">
            Como <strong>Soluciones comerciales</strong>, agregamos valor mediante la conexión con entidades de gobierno y terceros para ofrecer acceso a documentos de consumo público con alta demanda.
          </Typography>
          <Typography color="text.white" gutterBottom variant="body2" className="hero-title-comerciales">
            Además, brindamos la seguridad de una conexión confiable y válida con el gobierno para la certeza de los documentos  que emitimos.
          </Typography>
          <Box sx={{ my: { sm: '0.5rem', md: '0.5rem' }, width: {md:'320px', sm:'250px'} }}>
            <img src={comercialesLogo} alt="soluciones comerciales" width={"100%"}/>
          </Box>
          <Link to='/contacto'>
            <Button variant="outlined" sx={{ my: { sm: '0.5rem', md: '0.5rem' } }} color="primary" className="ing-index-hero__button" >
              Contáctanos
            </Button>
          </Link>
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' }, height:'560px' }} item xs={12} md={6} >
          <img src={comercialesHero} alt="intersect img" className="ing-index-hero__img"/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ComercialesHero;