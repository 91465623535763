import React from 'react';
import { LocaleTypes } from '../data/locales';

import {
  ComercialesHero,
  ComercialesDocuments,
  ComercialCaseSNR,
  ComercialesCTA
} from '../components/comerciales';

import IndexLayout from '../layouts';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import '../styles/styles.scss'

interface ComercialesProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location
}

const comerciales: React.FC<ComercialesProps> = ({ pathContext, location }) => {

  const content = (
    <>
      <SEO title="Soluciones Comerciales" description="Soluciones Comerciales" />

      <div className="ingenio-bg-hero-comerciales">
        <ComercialesHero />
      </div>

      <ComercialesDocuments />

      <ComercialCaseSNR />

      <ComercialesCTA />

      <div className="ingenio-bg-footer">
        <Footer />
      </div>
    </>
  )

  return (
    <IndexLayout location={location} content={content}  />
  )
}

export default comerciales;